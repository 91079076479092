import dynamic from "next/dynamic";
import { SessionProvider } from "next-auth/react";
import { AppWrapper } from "../context/App-context";
import "../styles/index.css";

import mixpanel from "mixpanel-browser";
import { Mixpanel } from "../lib/mixpanel";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import { getUserID } from "../lib/utils";
const CrispWithNoSSR = dynamic(() => import("@/components/widgets/crisp"), {
  ssr: false,
});

const tagManagerArgs = {
  gtmId: process.env.NEXT_PUBLIC_GTM_ID,
};

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
      api_host: `${process.env.NEXT_PUBLIC_HOST}/mp`,
      // debug: true,
    });
    TagManager.initialize(tagManagerArgs);
  }, []);

  // Mixpanel tracking
  useEffect(() => {
    // Track initial page load

    // Delay the Mixpanel tracking code execution by 500 ms for title to be set
    setTimeout(() => {
      Mixpanel.track("Page Viewed", {
        "Page Title": document.title,
      });
    }, 500);

    const handleRouteChange = (url) => {
      // Delay the Mixpanel tracking code execution by 500 ms for title to be set
      setTimeout(() => {
        Mixpanel.track("Page Viewed", {
          "Page Title": document.title,
        });
      }, 500);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  function SessionHandler() {
    const { data: session, status } = useSession();
    const loading = status === "loading";

    useEffect(() => {
      if (!loading && session) {
        Mixpanel.identify(getUserID(session));
        // push the user's userid for sprig
        try {
          Sprig("setUserId", getUserID(session));
        } catch (err) {
          console.log(err);
        }
      }
    }, [session, loading]);

    return null;
  }

  return (
    <>
      <AppWrapper>
        <SessionProvider session={pageProps.session} refetchInterval={0}>
          <SessionHandler />
          <CrispWithNoSSR />
          <Component {...pageProps} />
        </SessionProvider>
      </AppWrapper>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(l,e,a,p) {
            if (window.Sprig) return;
            window.Sprig = function(){S._queue.push(arguments)}
            var S = window.Sprig;S.appId = a;S._queue = [];window.UserLeap=S;
            a=l.createElement('script');
            a.async=1;a.src=e+'?id='+S.appId;
            p=l.getElementsByTagName('script')[0];
            p.parentNode.insertBefore(a, p);
          })(document, 'https://cdn.sprig.com/shim.js', '${process.env.NEXT_PUBLIC_SPRIG_ENVIRONMENT_ID}');
        `,
        }}
      ></script>
    </>
  );
}

export default MyApp;
